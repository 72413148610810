import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  navItems: [
    // {
    //   name: "Lobby",
    //   route: "/",
    //   show: true,
    // },
    {
      name: "Auditorium",
      route: "/",
      show: true,
    },
    // {
    //   name: "Networking",
    //   route: "/networking",
    //   show: true,
    // icon:"Diversity3Icon"
    // },
    // {
    //   name: "Exhibition",
    //   route: "/exhibition",
    //   show: true,
    // icon:"StadiumIcon"
    // },
    // {
    //   name: "Gallery",
    //   show: true,
    // icon:"PhotoLibraryIcon"
    // },
    {
      name: "Agenda",
      show: true,
    },
    {
      name: "FAQ",
      show: true,
    },
    {
      name: "Logout",
      route: "/login",
      show: true,
    },
  ],
};

export const lobbySlice = createSlice({
  name: "lobby",
  initialState,
  reducers: {
    setNavItems: (state, action) => {
      const { index, show } = action.payload;
      state.navItems[index].show = show;
    },
  },
});

export const { setNavItems } = lobbySlice.actions;
export const lobbyState = (state) => state.lobby;

export default lobbySlice.reducer;
