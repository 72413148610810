import * as React from "react";
import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import {
  authState,
  setFormModal,
  authWithPdfAsync,
  authWithTextAsync,
} from "../reduxSlice";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Switch,
  Textarea,
} from "@mui/joy";
import { adminState } from "../../AdminRole/reduxSlice";
export default function AddForm() {
  const dispatch = useDispatch();
  const { accessLevel } = useSelector(adminState);
  const [files, setFiles] = React.useState("");
  const [pdf, setPdf] = React.useState("");
  const [preview, setPreview] = React.useState("");
  const [checked, setChecked] = React.useState(false);
  const { register, handleSubmit, reset } = useForm();
  const onSelect = (e) => {
    setFiles(e.target.files[0]);
    setPreview(URL.createObjectURL(e.target.files[0]));
  };
  const onSelectPDF = (e) => setPdf(e.target.files[0]);

  const updateAuthPage = (data) => {
    const { checkMeContent } = data;
    var formdata = new FormData();
    formdata.append("accessLevel", accessLevel);
    if (files && !checked) {
      formdata.append("", files);
      formdata.append("checkMeContent", checkMeContent);
      dispatch(authWithTextAsync({ formdata }));
    } else if (files && checked) {
      formdata.append("", files);
      formdata.append("pdf", pdf);
      dispatch(authWithPdfAsync({ formdata }));
    } else {
      alert("Please Fill Required Fields");
      return;
    }
    dispatch(setFormModal({ value: false }));
    reset();
  };
  return (
    <Box
      component="form"
      sx={{ display: "flex", flexDirection: "column", gap: 2 }}
      onSubmit={handleSubmit(updateAuthPage)}
    >
      <Typography variant="h6" component="h6" className="text-center">
        Log In
      </Typography>

      <FormControl sx={{ position: "relative" }}>
        <FormLabel>Background image size : 2560 x 1440</FormLabel>
        <Input
          size="sm"
          type="file"
          slotProps={{
            input: { accept: "image/*" },
          }}
          sx={{
            position: "absolute",
            opacity: 0,
            height: "100%",
            width: "100%",
          }}
          onChange={onSelect}
        />
        <Box
          sx={{
            border: "1px dashed #d7d7d7",
            borderRadius: 5,
            height: 300,
            width: 350,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: `no-repeat center / contain url(${
              preview ? preview : null
            })`,
          }}
        >
          <Typography>Upload Image</Typography>
        </Box>
      </FormControl>
      <FormControl
        orientation="horizontal"
        sx={{ justifyContent: "space-between" }}
      >
        <div>
          <FormLabel>Content for Check Me In</FormLabel>
        </div>
        <Switch
          checked={checked}
          onChange={(event) => setChecked(event.target.checked)}
          color={checked ? "success" : "neutral"}
          variant={checked ? "solid" : "outlined"}
          startDecorator={"Text"}
          endDecorator={"PDF"}
        />
      </FormControl>
      <FormControl>
        {checked ? (
          <Input
            size="sm"
            type="file"
            onChange={onSelectPDF}
            slotProps={{
              input: { accept: "application/pdf" },
            }}
          />
        ) : (
          <Textarea
            minRows={3}
            size="sm"
            type="text"
            {...register("checkMeContent")}
          />
        )}
      </FormControl>

      <Button size="sm" type="submit" variant="solid">
        Submit
      </Button>
    </Box>
  );
}
