import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { functions } from "../../../firebase/config";
import { httpsCallable } from "firebase/functions";
const initialState = {
  loading: false,
  collection: "",
};

export const auditoriumPostAsync = createAsyncThunk(
  "auditorium/postAuditorium",
  async ({ formdata }, { dispatch, getState, rejectWithValue }) => {
    try {
      const api =
        "https://asia-south1-virtual-premium-platform.cloudfunctions.net/postAuditorium";
        // "http://localhost:5001/virtual-premium-platform/asia-south1/postAuditorium";
      const response = await axios.post(api, formdata);
      return response;
    } catch (error) {
      if (error.name === "FirebaseError") {
        console.log(error.code, error.message);
      }
      return rejectWithValue(error);
    }
  }
);

export const auditoriumUpdateAsync = createAsyncThunk(
  "auditorium/updateAuditorium",
  async ({ formdata }, { dispatch, getState, rejectWithValue }) => {
    try {
      const api =
        "https://asia-south1-virtual-premium-platform.cloudfunctions.net/updateAuditorium";
        // "http://localhost:5001/virtual-premium-platform/asia-south1/updateAuditorium";
      const response = await axios.post(api, formdata);
      return response;
    } catch (error) {
      if (error.name === "FirebaseError") {
        console.log(error.code, error.message);
      }
      return rejectWithValue(error);
    }
  }
);

export const getAuditoriumAsync = createAsyncThunk(
  "auditorium/getAuditorium",
  async (
    { navigate, accessLevel },
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const func = httpsCallable(functions, "getAuditorium");
      const response = await func({ accessLevel });
      return response;
    } catch (error) {
      if (error.name === "FirebaseError") {
        console.log(error.code, error.message);
      }
      return rejectWithValue(error);
    }
  }
);
export const auditoriumSlice = createSlice({
  name: "auditorium",
  initialState,
  reducers: {
    setIsAuthenticated: (state, action) => {
      state.isAuthenticated = action.payload.value;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(auditoriumPostAsync.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(auditoriumPostAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.collection = action.payload.data;
      })
      .addCase(auditoriumPostAsync.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(auditoriumUpdateAsync.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(auditoriumUpdateAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.collection = action.payload.data;
      })
      .addCase(auditoriumUpdateAsync.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getAuditoriumAsync.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getAuditoriumAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.collection = action.payload.data;
      })
      .addCase(getAuditoriumAsync.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export const { setIsAuthenticated } = auditoriumSlice.actions;
export const auditoriumState = (state) => state.auditorium;
export default auditoriumSlice.reducer;
