import * as React from "react";
import { Box, Input, Button } from "@mui/joy";
import { Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { setFormModal } from "../../Auth/reduxSlice";
export default function AuditoriumEntrance() {
  const dispatch = useDispatch();
  const { register, handleSubmit, reset } = useForm();
  const handlePost = (data) => {
    console.log("Entrance", data);
    reset();
    dispatch(setFormModal({ value: false }));
  };
  return (
    <Box
      component="form"
      sx={{ display: "flex", flexDirection: "column", gap: 2 }}
      onSubmit={handleSubmit(handlePost)}
    >
      <Typography variant="h6" component="h6" className="text-center">
        Auditorium Entrance
      </Typography>
      <Input
        type="file"
        accept="video/mp4"
      />
      <Button size="sm" variant="solid" type="submit">
        Submit
      </Button>
    </Box>
  );
}
