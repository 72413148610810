import * as React from "react";
import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { authState } from "./reduxSlice";
import { useSelector, useDispatch } from "react-redux";
import CustomCountdown from "../Count/Count";
import CheckIcon from "@mui/icons-material/Check";
import FormModal from "../Utils/FormModal";
import {
  Button,
  Checkbox,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Modal,
  Sheet,
} from "@mui/joy";
import { adminState } from "../AdminRole/reduxSlice";
import UpdateFrom from "./Form/UpdateFrom";
import AddForm from "./Form/AddForm";
import LoginForm from "./Form/LoginForm";
import PdfViewer from "../Utils/PdfViewer";

export default function SignInSide() {
  const { collection, counterOver } = useSelector(authState);
  const { role } = useSelector(adminState);
  const [open, setOpen] = React.useState(false);

  const [isChecked, setIsChecked] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      {role === "admin" && (
        <FormModal>{collection ? <UpdateFrom /> : <AddForm />}</FormModal>
      )}

      <Box
      
        sx={{
          height: "100vh",
          background: `no-repeat center / auto 100% url(${
            collection
              ? collection.backgroundImage?.Location
              : "https://virtualpf.s3.ap-south-1.amazonaws.com/afrisummit/auth/Untitled-2.png"
          })`,
          position: "relative",
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "40vh",
            left: "15vw",
            width: "30vw",
            height: "25vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            // overflow: "hidden",
          }}
        >
          {role === "admin" || role === "organizer" || counterOver ? (
            <LoginForm handleOpen={handleOpen} isChecked={isChecked} />
          ) : (
            <CustomCountdown />
          )}
        </Box>
      </Box>
      <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-desc"
        open={open}
        onClose={handleClose}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-start",
        }}
      >
        <Sheet
          variant="outlined"
          sx={{
            minWidth: 375,
            width: 600,
            borderRadius: "md",
            p: 3,
            mx:2,
            boxShadow: "lg",
           
          }}
        >
          <Box
            sx={{
              width: "100%",
              minHeight: "100%",
              maxHeight:"80vh",
              overflowY: "auto",
            }}
          >
            {collection ? (
              collection.checkMeContent.Location ? (
                <PdfViewer item={collection.checkMeContent.Location} />
              ) : (
                <Typography component={"p"} variant="div">
                  {collection?.checkMeContent}
                </Typography>
              )
            ) : (
              <ul>
                <li>
                  See the documentation below for a complete reference to all of
                  the props and classes available to the components mentioned
                  here.
                </li>
                <li>Sample text</li>
                <li>
                  See the documentation below for a complete reference to all of
                  the props and classes available to the components mentioned
                  here.
                </li>
                <li>Sample text</li>
                <li>
                  See the documentation below for a complete reference to all of
                  the props and classes available to the components mentioned
                  here.
                </li>
                <li>Sample text</li>
              </ul>
            )}
          </Box>
          <Box className="d-flex">
            <Button
              size="sm"
              className="ms-auto"
              variant="solid"
              startDecorator={<CheckIcon />}
              onClick={() => {
                handleClose();
                setIsChecked(true);
              }}
            >
              OK
            </Button>
          </Box>
        </Sheet>
      </Modal>
    </>
  );
}
