import React from "react";
import { Box, Grid, IconButton, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { exhibitionState } from "./reduxSlice";
import { useForm } from "react-hook-form";
import FormModal from "../Utils/FormModal";
import { Button, Divider, FormControl, FormLabel, Input } from "@mui/joy";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { pdfDemo } from "./Booth";

export default function Form() {
  const { selectedExhibition } = useSelector(exhibitionState);
  const [pdfCount, setPdfCount] = React.useState(
    // selectedExhibition?.pdfs ? selectedExhibition?.pdfs.length : 0
    pdfDemo.length
  );
  const { register, handleSubmit, getValues } = useForm();
  const onSubmit = (data) => {
    // const updatedPosters = getValues("posters");
    // const updatePdfs = getValues("pdfs");
    // setAdmin((prevAdmin) => ({
    //   ...prevAdmin,
    //   posters: updatedPosters,
    //   pdfs: updatePdfs,
    // }));
    console.log(data);
  };

  const handleAddPdf = () => {
    setPdfCount(pdfCount + 1);
  };
  const handleRemovePdf = (index) => {
    setPdfCount(pdfCount - 1);
    // setAdmin((prevAdmin) => ({
    //   ...prevAdmin,
    //   pdfs: prevAdmin.pdfs ? prevAdmin.pdfs.filter((_, i) => i !== index) : [],
    // }));
  };
  return (
    <FormModal>
      <Box
        component="form"
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="h6" component="h6" className="text-center">
              Exhibition Booth
            </Typography>
            <Divider />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl size="sm">
              <FormLabel>Background Image Url</FormLabel>
              <Input type="file" accept="image/*" {...register("bgImg")} />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl size="sm">
              <FormLabel>Youtube link</FormLabel>
              <Input type="text" {...register("youtubeLink")} />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl size="sm">
              <FormLabel>Embedded Chat link</FormLabel>
              <Input type="text" {...register("embeddedChatLink")} />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl size="sm">
              <FormLabel>Embedded Video Chat link</FormLabel>
              <Input type="text" {...register("embeddedVideoChat")} />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="subtitle2"
              component="p"
              className="text-center"
            >
              -------- POSTERS --------
            </Typography>
          </Grid>
          {selectedExhibition.posters?.map((item, i) => (
            <Grid item xs={12} md={6} key={i}>
              <FormControl size="sm">
                <FormLabel>{"Poster " + (i + 1)}</FormLabel>
                <Input type="file" {...register(`posters.${i}`)} />
              </FormControl>
            </Grid>
          ))}
          <Grid item xs={12}>
            <Box className="d-flex justify-content-between">
              <Typography
                variant="subtitle2"
                component="p"
                className="text-center"
              >
                -------- PDF's --------{" "}
              </Typography>
              <Button
                size="small"
                variant="soft"
                color="primary"
                startDecorator={<AddIcon />}
                onClick={handleAddPdf}
              >
                add
              </Button>
            </Box>
          </Grid>
          {Array.from({ length: pdfCount }, (_, i) => i).map((i) => (
            <Grid item xs={12} md={6} key={i}>
              <FormControl size="sm">
                <FormLabel>
                  {"PDF " + (i + 1)}
                  <IconButton
                    size="small"
                    sx={{ ml: 1 }}
                    onClick={() => handleRemovePdf(i)}
                  >
                    <DeleteIcon sx={{ fontSize: 14 }} />
                  </IconButton>
                </FormLabel>
                <Input type="file" {...register(`pdfs.${i}`)} />
              </FormControl>
            </Grid>
          ))}
        </Grid>

        <Button size="sm" type="submit" variant="solid">
          Submit
        </Button>
      </Box>
    </FormModal>
  );
}
