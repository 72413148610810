import { Box, IconButton } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { exhibitionState, setSelectedExhibition } from "./reduxSlice";
import { setDrawerPdf, setModal, setOpenDrawer } from "../Auth/reduxSlice";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import PictureAsPdfOutlinedIcon from "@mui/icons-material/PictureAsPdfOutlined";
import BackButton from "../Utils/BackButton";
import CustomModal from "../Lobby/components/CustomModal";
import { Button } from "@mui/joy";
import Form from "./Form";
import { adminState } from "../AdminRole/reduxSlice";

const bgImages = [
  "https://s3.ap-south-1.amazonaws.com/img.nularksolutions.com/Hubplus-Afrisummit/images/exhibition-PRA.jpg",
  "https://s3.ap-south-1.amazonaws.com/img.nularksolutions.com/Hubplus-Afrisummit/images/exhibition-Hubplus.jpg",
  "https://s3.ap-south-1.amazonaws.com/img.nularksolutions.com/Hubplus-Afrisummit/images/exhibition-Zoetis.jpg",
  "https://s3.ap-south-1.amazonaws.com/img.nularksolutions.com/Hubplus-Afrisummit/images/exhibition-Elanco.jpg",
];
const youtubeVideoLink = "https://www.youtube.com/embed/3UGDNAEnFyk";
const posterDemoImage =
  "https://plus.unsplash.com/premium_photo-1690522531276-24c679f29b6a?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1770&q=80";
export const pdfDemo = [
  {
    name: "pdf1",
    pdf: "https://s3.ap-south-1.amazonaws.com/img.nularksolutions.com/Hubplus-Afrisummit/images/Sample-pdf.pdf",
  },
  {
    name: "pdf2",
    pdf: "https://s3.ap-south-1.amazonaws.com/img.nularksolutions.com/Hubplus-Afrisummit/images/Sample-pdf.pdf",
  },
];

export default function Booth() {
  const dispatch = useDispatch();
  const { selectedExhibition, exhibitionList } = useSelector(exhibitionState);
  const { role, accessLevel } = useSelector(adminState);
  const handleOpenPDF = (pdfs) => {
    dispatch(setDrawerPdf({ pdf: "", chat: "", vidChat: "", list: pdfs }));
    dispatch(setOpenDrawer({ value: true }));
  };

  const handleImage = (imgSrc) => {
    if (imgSrc) {
      dispatch(
        setModal({
          modal: {
            state: true,
            imageSrc: imgSrc,
            videoSrc: "",
          },
        })
      );
    }
  };

  return (
    <>
      {role === "admin" ? <Form /> : null}
      <BackButton />

      {selectedExhibition ? (
        <Box
          sx={{
            background: `url(${bgImages[selectedExhibition.id - 1]})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "100% 100%",
            width: "100%",
            height: "100vh",
            "& .hubplus-video": [
              {
                position: "absolute",
                border: "none",
              },
              selectedExhibition.youtubePosition
                ? selectedExhibition.youtubePosition
                : null,
            ],
          }}
        >
          <iframe
            title="booth"
            className="hubplus-video"
            src={`${youtubeVideoLink}?&autoplay=1&mute=1`}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen=""
          ></iframe>

          {selectedExhibition.posterPositions?.map((item, i) => (
            <Box
              key={i}
              className="posters"
              onClick={() => handleImage(posterDemoImage)}
              sx={[
                {
                  cursor: "pointer",
                  position: "absolute",
                  border: 1,
                },
                item,
              ]}
            ></Box>
          ))}

          <Box
            sx={[
              {
                cursor: "pointer",
                position: "absolute",
              },
              selectedExhibition.rackPosition,
            ]}
            onClick={() => handleOpenPDF(pdfDemo)}
          >
            <Button
              variant="plain"
              sx={{
                top: "2.5vh",
                left: "-0.5vh",
              }}
            >
              <PictureAsPdfOutlinedIcon />
            </Button>
          </Box>

          <Box
            onClick={() => dispatch(setOpenDrawer({ value: true }))}
            className="chat"
            sx={[
              {
                cursor: "pointer",
                position: "absolute",
              },
              selectedExhibition.chatPosition,
            ]}
          ></Box>
          <Box
            onClick={() => dispatch(setOpenDrawer({ value: true }))}
            className="videoChat"
            sx={[
              {
                cursor: "pointer",
                position: "absolute",
              },
              selectedExhibition.videoChat,
            ]}
          ></Box>

          <IconButton
            className="nextBtn "
            onClick={() =>
              dispatch(
                setSelectedExhibition(
                  exhibitionList[selectedExhibition.nextItem]
                )
              )
            }
            size="large"
            sx={{
              position: "absolute",
              right: "3vw",
              top: "50%",
              color: "mediumseagreen",
              textShadow: "2px 2px 8px black",
            }}
          >
            <ArrowForwardIosIcon
              sx={{
                fontSize: "40px",
              }}
            />
          </IconButton>
          <IconButton
            className="prevBtn"
            onClick={() =>
              dispatch(
                setSelectedExhibition(
                  exhibitionList[selectedExhibition.previousItem]
                )
              )
            }
            size="large"
            sx={{
              position: "absolute",
              left: "5vw",
              top: "50%",
              color: "mediumseagreen",
            }}
          >
            <ArrowBackIosIcon
              sx={{
                fontSize: "40px",
                ml: 1,
              }}
            />
          </IconButton>
        </Box>
      ) : null}
      <CustomModal />
    </>
  );
}
