import React from "react";
import { Box } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setDrawerPdf, setModal, setOpenDrawer } from "../../Auth/reduxSlice";

export default function BlinkButton({ sx, route, imgSrc, pdf, videoSrc }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const openMediaModal = (imageSrc, videoSrc) => {
    dispatch(
      setModal({
        modal: {
          state: true,
          imageSrc,
          videoSrc,
        },
      })
    );
  };
  return (
    <Box className="btn-wrapper" sx={{}}>
      <Box className="bg" sx={sx}></Box>
      <Box
        className="frame"
        onClick={() => {
          if (pdf) {
            dispatch(setDrawerPdf({ pdf, chat: "", vidChat: "", list: "" }));
            dispatch(setOpenDrawer({ value: true }));
          } else if (imgSrc) {
            openMediaModal(imgSrc, "");
          } else if (videoSrc) {
            openMediaModal("", videoSrc);
          } else if (route) {
            navigate(route);
          }
        }}
        sx={[
          sx,
          {
            position: "absolute",
            bgcolor: "transparent",
            transform: "translate(-50%, -80%)",
            width: 50,
            height: 80,
            zIndex: 10,
            cursor: "pointer",
          },
        ]}
      ></Box>
      <AddIcon className="button" sx={sx} />
    </Box>
  );
}
