import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Divider,
  Experimental_CssVarsProvider,
  Grid,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Button, ModalClose } from "@mui/joy";
import Drawer from "@mui/joy/Drawer";
import { authState, setDrawerPdf, setOpenDrawer } from "../Auth/reduxSlice";
import PdfViewer from "./PdfViewer";
export default function SideDrawer() {
  const dispatch = useDispatch();
  const { openDrawer, drawer } = useSelector(authState);
  const [showList, setShowList] = React.useState({
    state: true,
    item: "",
  });

  const handleClose = () => {
    dispatch(setOpenDrawer({ pdf: "", chat: "", vidChat: "", list: "" }));
    dispatch(setDrawerPdf({ value: "" }));
  };
  const handleBackButtonClick = () => {
    setShowList({ state: true, item: "" });
  };
  const renderBackButton = drawer.list && !showList.state && (
    <Button
      size="sm"
      variant="soft"
      className="me-2"
      sx={{ textTransform: "lowercase" }}
      onClick={handleBackButtonClick}
      startDecorator={<ArrowBackIcon />}
    >
      back
    </Button>
  );

  return (
    <div>
      <Drawer
        anchor="right"
        open={openDrawer}
        onClose={handleClose}
        hideBackdrop={{
          invisible: true,
        }}
      >
        <ModalClose />

        <Box
          sx={{
            width: "32vw",
            height: "100%",
            p: 1,
          }}
        >
            <Grid container spacing={1} sx={{ height: "100%" }}>
              <Grid item xs={12} sx={{ display: "flex" }}>
                {renderBackButton}
                {/* {renderDownloadButton} */}
                <IconButton
                  size="small"
                  sx={{ ml: "auto" }}
                  onClick={handleClose}
                >
                  <CloseIcon sx={{ fontSize: 15 }} />
                </IconButton>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12} sx={{ height: "100%" }}>
                {drawer.pdf && <PdfViewer item={drawer.pdf} />}
                {drawer.chat && (
                  <iframe
                    src={drawer.chat}
                    title="Chat URL"
                    style={{
                      border: 0,
                      width: "100%",
                      height: "100%",
                      borderRadius: "4px",
                    }}
                  />
                )}
                {drawer.feedbackLink && (
                  <iframe
                    src={drawer.feedbackLink}
                    title="Feedback form"
                    style={{
                      border: 0,
                      width: "100%",
                      height: "100%",
                      borderRadius: "4px",
                    }}
                  />
                )}
                {drawer.list ? (
                  showList.state ? (
                    drawer.list.map((item, i) => (
                      <Box
                        className="d-flex flex-column align-items-start"
                        key={i}
                      >
                        <Button
                          size="sm"
                          variant="plain"
                          onClick={() => {
                            setShowList({
                              state: false,
                              item: item.pdf,
                            });
                          }}
                        >
                          {item.name}
                        </Button>
                      </Box>
                    ))
                  ) : (
                    <PdfViewer item={showList.item} />
                  )
                ) : null}
              </Grid>
            </Grid>
        </Box>
      </Drawer>
    </div>
  );
}
