import React from "react";
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Button, FormControl, FormLabel, Input } from "@mui/joy";
import { adminLoginAsync, adminState } from "./reduxSlice";
import { useDispatch, useSelector } from "react-redux";

export default function AdminPortal() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { register, handleSubmit } = useForm();
  const { loading } = useSelector(adminState);

  const handleLogin = (data) => {
    if (data.email && data.password) {
      let email = data.email;
      let password = data.password;
      dispatch(adminLoginAsync({ email, password, navigate }));
    } else {
      alert("please fill details ");
    }
  };

  return (
    <Box
      className="text-center d-flex flex-column justify-content-center align-items-center"
      sx={{
        background:
          "no-repeat url(https://images.unsplash.com/photo-1634655377962-e6e7b446e7e9?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=764&q=80)",
        backgroundSize: "cover",
        height: "100vh",
      }}
    >
      <Typography variant="h5" component="h5" className="mb-4">
        Admin Portal
      </Typography>

      <Box
        component="form"
        onSubmit={handleSubmit(handleLogin)}
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <FormControl>
          <FormLabel>Email</FormLabel>
          <Input
            {...register("email")}
            type="email"
            variant="outlined"
            placeholder="email"
          />
        </FormControl>
        <FormControl>
          <FormLabel>Password</FormLabel>
          <Input
            {...register("password")}
            type="password"
            variant="outlined"
            placeholder="password"
          />
        </FormControl>
        <Button
          type="submit"
          variant="solid"
          className="px-5 w-100"
          loading={loading}
        >
          Log In
        </Button>
      </Box>
    </Box>
  );
}
