import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { authState, setFormModal } from "../Auth/reduxSlice";
import { Box, Modal, ModalClose, Sheet } from "@mui/joy";
import { adminState } from "../AdminRole/reduxSlice";

export default function FormModal({ children }) {
  const { role } = useSelector(adminState);
  const { formModal } = useSelector(authState);
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(setFormModal({ value: false }));
  };
  return (
    <>
      {role === "admin" ? (
        <Modal
          aria-labelledby="modal-title"
          aria-describedby="modal-desc"
          open={formModal}
          onClose={handleClose}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Sheet
            variant="outlined"
            sx={{
              maxWidth: 500,
              borderRadius: "md",
              p: 3,
              boxShadow: "lg",
              border: 0,
            }}
          >
            <ModalClose
              variant="outlined"
              sx={{
                top: "calc(-1/4 * var(--IconButton-size))",
                right: "calc(-1/4 * var(--IconButton-size))",
                boxShadow: "0 2px 12px 0 rgba(0 0 0 / 0.2)",
                borderRadius: "50%",
                bgcolor: "background.surface",
              }}
            />
            <Box
              sx={{
                maxHeight: 500,
                overflow: "auto",
              }}
            >
              {children}
            </Box>
          </Sheet>
        </Modal>
      ) : null}
    </>
  );
}
