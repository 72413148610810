import { Button } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

export default function BackButton() {
  const navigate = useNavigate();
  return (
    <Button
      // endIcon={<SkipNextIcon />}
      sx={{
        position: "absolute",
        right: 20,
        top: 20,
        zIndex: 10,
        borderRadius: 50,
        px: 4,
        backgroundColor: "#f9a825 !important",
        color: "#000 !important",
        textTransform: "capitalize",
      }}
      onClick={() => navigate(-1)}
    >
      Back
    </Button>
  );
}
