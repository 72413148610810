import { Box, Container, Typography } from "@mui/material";
import React from "react";
import { setDrawerPdf, setFormModal, setOpenDrawer } from "../Auth/reduxSlice";
import { useDispatch } from "react-redux";
import BackButton from "../Utils/BackButton";
import FormModal from "../Utils/FormModal";
import { useForm } from "react-hook-form";
import { Button, Divider, FormControl, FormLabel, Input } from "@mui/joy";

const rooms = [
  {
    name: "Ram Room",
    position: {
      left: "13.5vw",
      top: " 0.5vh",
    },
    chatLink: "",
  },
  {
    name: "Camel Room",
    position: {
      right: "13.5vw",
      top: " 0.5vh",
    },
    chatLink: "",
  },
  {
    name: "Bull Room",
    position: {
      left: "13.5vw",
      bottom: " 0.5vh",
    },
    chatLink: "",
  },
  {
    name: "Stallion Room",
    position: {
      right: "13.5vw",
      bottom: " 0.5vh",
    },
    chatLink: "",
  },
];

export default function Networking() {
  const dispatch = useDispatch();
  const isAdmin = window.sessionStorage.getItem("adminAccess");
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [admin, setAdmin] = React.useState({
    bgImg:
      "https://s3.ap-south-1.amazonaws.com/img.nularksolutions.com/Hubplus-Afrisummit/images/networkingZone.jpg",
    ramRoom: "",
    camelRoom: "",
    bullRoom: "",
    stallionRoom: "",
  });

  const onSubmit = (data) => {
    console.log("netwroking", data);
    // setAdmin((prev) => ({
    //   ...prev,
    //   bgImg: data.bgImg,
    //   ramRoom: data.ramRoom,
    //   camelRoom: data.camelRoom,
    //   bullRoom: data.bullRoom,
    //   stallionRoom: data.stallionRoom,
    // }));

    dispatch(setFormModal({ value: false }));
  };
  return (
    <>
      {isAdmin ? (
        <FormModal>
          <Box
            component="form"
            sx={{ display: "flex", flexDirection: "column", gap: 1 }}
            onSubmit={handleSubmit(onSubmit)}
          >
            <Typography variant="h6" component="h6" className="text-center">
              Networking
            </Typography>
            <Divider />
            <FormControl size="sm">
              <FormLabel> background Image : 1920 x 1080</FormLabel>
              <Input
                type="file"
                {...register("bgImg")}
                accept="image/*"
                error={!!errors.bgImg}
              />
            </FormControl>
            <FormControl size="sm">
              <FormLabel>Ram Room</FormLabel>
              <Input
                type="text"
                {...register("ramRoom")}
                placeholder="embedded url "
              />
            </FormControl>
            <FormControl size="sm">
              <FormLabel>Camel Room</FormLabel>
              <Input
                type="text"
                {...register("camelRoom")}
                placeholder="embedded url "
              />
            </FormControl>
            <FormControl size="sm">
              <FormLabel>Bull Room</FormLabel>
              <Input
                type="text"
                {...register("bullRoom")}
                defaultValue={admin.bullRoom}
                placeholder="embedded url "
              />
            </FormControl>
            <FormControl size="sm">
              <FormLabel>Stallion Room</FormLabel>
              <Input
                type="text"
                {...register("stallionRoom")}
                placeholder="embedded url "
              />
            </FormControl>
            <Button size="sm" variant="solid" type="submit">
              Submit
            </Button>
          </Box>
        </FormModal>
      ) : null}
      <Container
        maxWidth="xl"
        sx={{
          background: `no-repeat center/ 100% 100% url(${admin.bgImg})`,
          height: "100vh",
        }}
      >
        <BackButton />
        {rooms.map((item, i) => (
          <Box
            key={i}
            sx={[
              {
                cursor: "pointer",
                position: "absolute",
                width: "35.8vw",
                height: "49vh",
              },
              item.position,
            ]}
            onClick={() => {
              dispatch(
                setDrawerPdf({
                  pdf: "",
                  chat: "chat",
                  vidChat: "",
                  list: "",
                })
              );
              dispatch(setOpenDrawer({ value: true }));
            }}
          ></Box>
        ))}
      </Container>
    </>
  );
}
