import { combineReducers, configureStore } from "@reduxjs/toolkit";
import authReducer from "../component/component/Auth/reduxSlice";
import adminReducer from "../component/component/AdminRole/reduxSlice";
import exhibitionReducer from "../component/component/Exhibition/reduxSlice";
import lobbyReducer from "../component/component/Lobby/reducSlice";
import auditoriumReducer from "../component/component/Auditorium/reduxSlice";
const reducers = combineReducers({
  admin: adminReducer,
  auth: authReducer,
  exhibition: exhibitionReducer,
  lobby: lobbyReducer,
  auditorium: auditoriumReducer,
});

export const store = configureStore({
  reducer: reducers,
});
