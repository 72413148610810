import React, { useRef } from "react";
import { Box, Button, Typography } from "@mui/material";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import SkipNextIcon from "@mui/icons-material/SkipNext";

export default function Entrance({
  handleEntrance,
  welcomeMsg,
  vidSrc,
  autoPlay,
}) {
  const videoRef = useRef(null);
  const [play, setPlay] = React.useState(false);

  const handlePlayLobbyVideo = () => {
    setPlay(true);
    videoRef.current.play();
    videoRef.current.muted = false;
  };
  const handleVideoEnded = () => {
    handleEntrance();
  };
  return (
    <Box
      className="entrance"
      sx={{
        width: "100%",
        height: "100%",
        overflow: "hidden !important",
      }}
    >
      {welcomeMsg ? (
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%,-50%)",
            p: 3,
            bgcolor: "rgba(255,255,255,0.6)",
            backdropFilter: "blur(8px)",
            WebkitBackdropFilter: "blur(8px)",
            display: play ? "none" : "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: 2,
            borderRadius: 2,
            zIndex: 100,
          }}
        >
          <Typography variant="h6" component="h6">
            {welcomeMsg}
          </Typography>
          <Button
            startIcon={<PlayArrowIcon />}
            variant="contained"
            onClick={handlePlayLobbyVideo}
          >
            Enter
          </Button>
        </Box>
      ) : null}
      <Button
        className="blink_me"
        endIcon={<SkipNextIcon />}
        sx={{
          position: "absolute",
          right: 20,
          top: 20,
          zIndex: 10,
          borderRadius: 50,
          px: 4,
        }}
        onClick={handleEntrance}
      >
        Skip
      </Button>

      <video
        id="myVideo"
        ref={videoRef}
        controls={false}
        onEnded={handleVideoEnded}
        playsInline
        style={{ padding: 0 }}
        width="100%"
        autoPlay={autoPlay}
      >
        <source src={vidSrc} type="video/mp4" />
      </video>
    </Box>
  );
}
