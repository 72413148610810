import { Box, Typography } from "@mui/material";
import React from "react";
import BlinkButton from "../Lobby/components/BlinkButton";
import { useDispatch, useSelector } from "react-redux";
import { exhibitionState, setSelectedExhibition } from "./reduxSlice";
import { useNavigate } from "react-router-dom";
import BackButton from "../Utils/BackButton";
import FormModal from "../Utils/FormModal";
import { useForm } from "react-hook-form";
import { Button, FormControl, FormLabel, Input } from "@mui/joy";
import { adminState } from "../AdminRole/reduxSlice";

export default function Exhibition() {
  const dispatch = useDispatch();
  const { role, accessLevel } = useSelector(adminState);
  const { exhibitionList } = useSelector(exhibitionState);
  const [exhibitionBgImg, setExhibitionBgImg] = React.useState(
    "https://s3.ap-south-1.amazonaws.com/img.nularksolutions.com/Hubplus-Afrisummit/images/exhibitionHall.jpg"
  );
  const navigate = useNavigate();
  const { register, handleSubmit } = useForm();

  const onSubmit = (data) => {
    console.log("exhibition ", data);
  };

  const handleNavigate = (index) => {
    dispatch(setSelectedExhibition(exhibitionList[index]));
    navigate("/booth");
  };
  return (
    <>
      {role === "admin" ? (
        <FormModal>
          <Box
            component="form"
            sx={{ display: "flex", flexDirection: "column", gap: 2 }}
            onSubmit={handleSubmit(onSubmit)}
          >
            <Typography variant="h6" component="h6" className="text-center">
              Exhibition
            </Typography>
            <FormControl size="sm">
              <FormLabel>Background Image</FormLabel>
              <Input type="file" {...register("exhibitionBgImg")} />
            </FormControl>
            <Button size="sm" variant="solid" type="submit">
              Submit
            </Button>
          </Box>
        </FormModal>
      ) : null}
      <BackButton />
      <Box
        sx={{
          background: `url(${exhibitionBgImg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "100% 100%",
          width: "100%",
          height: "100vh",
        }}
      >
        <BlinkButton
          className="pra"
          sx={{
            left: "16.5vw",
            top: "68vh",
          }}
        />
        <Box
          className="pra-overlay"
          onClick={() => handleNavigate(0)}
          sx={{
            position: "absolute",
            width: "22vw",
            height: "28vh",
            zIndex: 15,
            cursor: "pointer",
            left: "5vw",
            top: "68vh",
          }}
        ></Box>
        <BlinkButton
          className="hubplus-overlay"
          sx={{
            left: "33.5vw",
            top: "51vh",
          }}
        />
        <Box
          className="hubplus"
          onClick={() => handleNavigate(1)}
          sx={{
            position: "absolute",
            width: "19vw",
            height: "24vh",
            zIndex: 15,
            cursor: "pointer",
            left: "27vw",
            top: "50vh",
          }}
        ></Box>
        <BlinkButton
          className="zoetis"
          sx={{
            left: "65.5vw",
            top: "50.5vh",
          }}
        />
        <Box
          className="zoetis-overlay"
          onClick={() => handleNavigate(2)}
          sx={{
            position: "absolute",
            width: "19vw",
            height: "28vh",
            zIndex: 15,
            cursor: "pointer",
            left: "52vw",
            top: "46vh",
          }}
        ></Box>
        <BlinkButton
          className="elanco"
          sx={{
            left: "81.5vw",
            top: "68vh",
          }}
        />
        <Box
          className="elanco-overlay"
          onClick={() => handleNavigate(3)}
          sx={{
            position: "absolute",
            width: "25vw",
            height: "28vh",
            zIndex: 15,
            cursor: "pointer",
            left: "71vw",
            top: "68vh",
          }}
        ></Box>
      </Box>
    </>
  );
}
