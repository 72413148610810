import {
  Box,
  Checkbox,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Button,
} from "@mui/joy";
import * as React from "react";
import { useForm } from "react-hook-form";
import { authState, loginAsync } from "../reduxSlice";
import { useDispatch, useSelector } from "react-redux";
import { adminState } from "../../AdminRole/reduxSlice";
import { useNavigate } from "react-router-dom";

export default function LoginForm({ handleOpen, isChecked }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { register, handleSubmit, reset } = useForm();
  const [checkboxError, setCheckBoxError] = React.useState("");
  const { accessLevel } = useSelector(adminState);
  const { loadingButton } = useSelector(authState);
  const handleLogin = (data) => {
    const email = data.email;
    if (isChecked) {
      dispatch(loginAsync({ email, accessLevel, navigate }));
      reset();
      setCheckBoxError(false);
    } else {
      setCheckBoxError(true);
    }
  };
  return (
    <Box
      component="form"
      onSubmit={handleSubmit(handleLogin)}
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        width: "auto",
        minWidth: 300,
      }}
    >
      <FormControl
        sx={{
          mb: 1,
          "& .MuiFormHelperText-root": {
            fontSize: "10px",
          },
        }}
      >
        <FormLabel>Email</FormLabel>
        <Input
          variant="outlined"
          required
          name="email"
          placeholder="Email"
          type="email"
          id="email"
          {...register("email")}
        />
        <FormHelperText>
          {"We'll never share your email with anyone else"}
        </FormHelperText>
      </FormControl>

      <Checkbox
        label="Check me in"
        size="sm"
        color="primary"
        onClick={(e) => {
          if (e.target.checked) {
            handleOpen();
          }
        }}
      />
      <FormHelperText sx={{ fontSize: "9px", color: "red" }}>
        {checkboxError ? "please check the checkbox" : ""}
      </FormHelperText>

      <Button
        type="submit"
        variant="solid"
        color="danger"
        className="mt-2"
        loading={loadingButton}
      >
        LOG iN
      </Button>
    </Box>
  );
}
