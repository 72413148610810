import { Route, Routes, Navigate, useNavigate } from "react-router-dom";
import "./App.css";
import Login from "./component/component/Auth/App";
import { useDispatch, useSelector } from "react-redux";
import { ref, onValue, set, update } from "firebase/database";
import {
  authState,
  getPagesAsync,
  logoutAsync,
  sessionUpdateAsync,
  setFormModal,
} from "./component/component/Auth/reduxSlice";
import * as React from "react";
import Lobby from "./component/component/Lobby/Lobby";
import Networking from "./component/component/Networking/Networking";
import Auditorium from "./component/component/Auditorium/Auditorium";
import Exhibition from "./component/component/Exhibition/Exhibition";
import Booth from "./component/component/Exhibition/Booth";
import LandscapeMessage from "./component/component/Utils/LandscapeMessage";
import AdminPortal from "./component/component/AdminRole/Admin";
import { Box, IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import SideDrawer from "./component/component/Utils/SideDrawer";
import BottomNav from "./component/component/Utils/BottomNav";
import { adminState } from "./component/component/AdminRole/reduxSlice";
import { getAuditoriumAsync } from "./component/component/Auditorium/reduxSlice";
import { db_database } from "./firebase/config";
import { Timestamp } from "firebase/firestore";
function App() {
  const { isAuthenticated, showNavbar } = useSelector(authState);
  const token = window.sessionStorage.getItem("token");
  const { role, accessLevel } = useSelector(adminState);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const PrivateRouteWrapper = ({ element: Element, ...rest }) => {
    return isAuthenticated ? (
      <Element {...rest} />
    ) : (
      <Navigate to="/login" replace />
    );
  };

  const AdminRouteWrapper = ({ element: Element, ...rest }) => {
    return <Element {...rest} />;
  };
  React.useEffect(() => {
    dispatch(getPagesAsync({ accessLevel }));
    dispatch(getAuditoriumAsync({ accessLevel }));
  }, [navigate]);

  let sessions = "";
  let previousSessionsRef = React.useRef();
  let previousUserSessionsRef = React.useRef();
  React.useEffect(() => {
    const auditoriumRef = ref(db_database, `status_session/auditorium/`);

    onValue(auditoriumRef, (snapshot) => {
      const newSessions = snapshot.val();
      if (newSessions !== previousSessionsRef.current) {
        sessions = newSessions;
        dispatch(getAuditoriumAsync({ accessLevel }));
        previousSessionsRef.current = newSessions;
      }
    });
  }, []);

  if (token && isAuthenticated) {
    const userRef = ref(db_database, `user_session_request/` + token);
    onValue(userRef, async (snapshot) => {
      const newSessions = snapshot.val();
      if (newSessions !== previousUserSessionsRef.current) {
        dispatch(sessionUpdateAsync({ accessLevel }));
        previousSessionsRef.current = newSessions;
      }
    });
  }


  return (
    <>
      <LandscapeMessage />
      {role === "admin" && (
        <IconButton
          sx={{
            position: "absolute",
            right: 20,
            bottom: 20,
            zIndex: 10,
            backgroundColor: "#f9a825 !important",
            color: "#000 !important",
            textTransform: "capitalize",
          }}
          onClick={() => dispatch(setFormModal({ value: true }))}
        >
          <EditIcon />
        </IconButton>
      )}
      {/* {isAuthenticated && (
        <Box
          className="bottomNavbar"
          sx={{
            display: showNavbar ? "auto" : "none",
            position: "absolute",
            bottom: 0,
            left: "50%",
            transform: "translate(-50%,0)",
            zIndex: 20,
          }}
        >
          <BottomNav />
        </Box>
      )} */}
      <Routes>
        {isAuthenticated ? (
          <Route>
            <Route path="/register" element={<Login />} />
            <Route path="/networking" element={<Networking />} />
            <Route path="/auditorium" element={<Auditorium />} />
            <Route path="/exhibition" element={<Exhibition />} />
            <Route path="/booth" element={<Booth />} />
          </Route>
        ) : null}
        <Route>
          <Route path="/login" element={<Login />} />
          <Route
            path="/"
            element={<PrivateRouteWrapper element={Auditorium} />}
          />
          <Route
            path="/admin"
            element={<AdminRouteWrapper element={AdminPortal} />}
          />
        </Route>
      </Routes>
      <SideDrawer />
    </>
  );
}

export default App;
