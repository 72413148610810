import * as React from "react";
import {
  Box,
  Divider,
  Experimental_CssVarsProvider,
  Grid,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Drawer from "@mui/joy/Drawer";
import PdfViewer from "./PdfViewer";
export default function SideDrawerProps({ props, open, handleClose }) {
  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={handleClose}
      hideBackdrop={{
        invisible: true,
      }}
    >
      <Box
        sx={{
          maxWidth: "32vw",
          height: "95vh",
          p: 1,
        }}
      >
        <Grid
          container
          spacing={0}
          rowSpacing={1}
          sx={{ height: "100%", m: 0 }}
        >
          <Grid item xs={12} sx={{ display: "flex" }}>
            <IconButton size="small" sx={{ ml: "auto" }} onClick={handleClose}>
              <CloseIcon sx={{ fontSize: 15 }} />
            </IconButton>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12} sx={{ height: "100%" }}>
            {props?.agendaLink && <PdfViewer item={props?.agendaLink} />}
            {props?.faqLink && <PdfViewer item={props?.faqLink} />}
            {props?.groupChatLink && (
              <iframe
                src={props?.groupChatLink}
                title="Chat URL"
                style={{
                  border: 0,
                  width: "100%",
                  height: "100%",
                  borderRadius: "4px",
                }}
              />
            )}
            {props?.feedbackLink && (
              <iframe
                src={props?.feedbackLink}
                title="Feedback form"
                style={{
                  border: 0,
                  width: "100%",
                  height: "100%",
                  borderRadius: "4px",
                }}
              />
            )}
          </Grid>
        </Grid>
      </Box>
    </Drawer>
  );
}
