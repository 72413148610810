import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../../firebase/config";
import { setIsAuthenticated } from "../Auth/reduxSlice";

const initialState = {
  loading: false,
  isAuthenticated: false,
  role: "user",
  accessLevel: "afrisummit",
};

export const adminLoginAsync = createAsyncThunk(
  "admin/adminLoginAPI",
  async (
    { email, password, navigate },
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const response = await signInWithEmailAndPassword(auth, email, password);
      const tokenResult = await response.user.getIdTokenResult();
      window.sessionStorage.getItem("role", tokenResult.claims.role);
      window.sessionStorage.getItem(
        "accessLevel",
        tokenResult.claims.accessLevel
      );
      if (tokenResult.claims.role === "organizer") {
        dispatch(setIsAuthenticated({ value: true }));
      }
      navigate("/");
      return tokenResult;
    } catch (error) {
      if (error.name === "FirebaseError") {
        console.log(error.code, error.message);
      }
      return rejectWithValue(error);
    }
  }
);

export const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    setSignUp: (state, action) => {
      state.signup = action.payload.value;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(adminLoginAsync.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(adminLoginAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.role = action.payload.claims.role;
        state.accessLevel = action.payload.claims.accessLevel;
      })
      .addCase(adminLoginAsync.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export const {} = adminSlice.actions;
export const adminState = (state) => state.admin;
export default adminSlice.reducer;
