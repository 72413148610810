import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  exhibitionList: [
    {
      id: 1,
      name: "PRA",
      youtubePosition: {
        width: "18vw",
        height: "20vh",
        top: "48vh",
        left: "17vw",
      },
      rackPosition: {
        left: "81vw",
        top: "67vh",
        width: "4vw",
        height: "26vh",
      },
      posterPositions: [
        {
          left: "41vw",
          top: "49vh",
          width: "9vw",
          height: "25vh",
        },
        {
          left: "57.9vw",
          top: "49vh",
          width: "8vw",
          height: "24vh",
        },
        {
          left: "66vw",
          top: "49vh",
          width: "8.5vw",
          height: "24vh",
        },
      ],
      posters: [1, 2, 3],
      chatPosition: {
        left: "46vw",
        bottom: "10vw",
        width: "3vw",
        height: "6vh",
      },
      videoChat: {
        left: "50vw",
        bottom: "10vw",
        width: "2vw",
        height: "6vh",
      },
      previousItem: 3,
      nextItem: 1,
    },
    {
      id: 2,
      name: "HubPlus",

      posters: [1, 2, 3, 4],
      youtubePosition: {
        width: "18vw",
        height: "20vh",
        top: "42.6vh",
        left: "65.7vw",
      },
      rackPosition: {
        left: "85vw",
        top: "67vh",
        width: "4vw",
        height: "26vh",
      },

      posterPositions: [
        {
          left: "22vw",
          top: "48vh",
          width: "8.6vw",
          height: "25vh",
        },
        {
          left: "31vw",
          top: "48vh",
          width: "8.6vw",
          height: "25vh",
        },
        {
          left: "39.8vw",
          top: "48vh",
          width: "8.6vw",
          height: "25vh",
        },
        {
          left: "48.7vw",
          top: "48vh",
          width: "8.6vw",
          height: "25vh",
        },
      ],
      chatPosition: {
        left: "9vw",
        bottom: "4vw",
        width: "3vw",
        height: "6vh",
      },
      videoChat: {
        left: "13vw",
        bottom: "4vw",
        width: "2vw",
        height: "6vh",
      },
      previousItem: 0,
      nextItem: 2,
    },
    {
      id: 3,
      name: "Zoetis",

      posters: [1, 2, 3],
      youtubePosition: {
        width: "18vw",
        height: "20vh",
        top: "48vh",
        left: "17vw",
      },
      rackPosition: {
        left: "81vw",
        top: "67vh",
        width: "4vw",
        height: "26vh",
      },
      posterPositions: [
        {
          left: "41vw",
          top: "49vh",
          width: "9vw",
          height: "25vh",
        },
        {
          left: "57.9vw",
          top: "49vh",
          width: "8vw",
          height: "24vh",
        },
        {
          left: "66vw",
          top: "49vh",
          width: "8.5vw",
          height: "24vh",
        },
      ],
      chatPosition: {
        left: "46vw",
        bottom: "10vw",
        width: "3vw",
        height: "6vh",
      },
      videoChat: {
        left: "50vw",
        bottom: "10vw",
        width: "2vw",
        height: "6vh",
      },
      previousItem: 1,
      nextItem: 3,
    },
    {
      id: 4,
      name: "Elanco",
      posters: [1, 2, 3, 4],
      youtubePosition: {
        width: "18vw",
        height: "20vh",
        top: "39vh",
        left: "64vw",
      },
      rackPosition: {
        left: "82vw",
        top: "67vh",
        width: "4vw",
        height: "26vh",
      },
      posterPositions: [
        {
          left: "22vw",
          top: "48vh",
          width: "9vw",
          height: "25vh",
        },
        {
          left: "31vw",
          top: "48vh",
          width: "8.6vw",
          height: "24vh",
        },
        {
          left: "39.8vw",
          top: "48vh",
          width: "8.5vw",
          height: "24vh",
        },
        {
          left: "48.7vw",
          top: "48vh",
          width: "8.5vw",
          height: "24vh",
        },
      ],
      chatPosition: {
        left: "12vw",
        bottom: "4vw",
        width: "3vw",
        height: "6vh",
      },
      videoChat: {
        left: "15vw",
        bottom: "4vw",
        width: "2vw",
        height: "6vh",
      },
      previousItem: 2,
      nextItem: 0,
    },
  ],
  selectedExhibition: "",
};

export const exhibitionSlice = createSlice({
  name: "exhibition",
  initialState,
  reducers: {
    setSelectedExhibition: (state, action) => {
      state.selectedExhibition = action.payload;
    },
  },
});

export const { setSelectedExhibition } = exhibitionSlice.actions;
export const exhibitionState = (state) => state.exhibition;
export default exhibitionSlice.reducer;
